import {
  Dom, Draggable, gsap, _defaultsDeep, imagesAreLoaded
} from '@univers-agency/jupiter'
import { InertiaPlugin } from '../Inertia/index'

gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)

const DEFAULT_OPTIONS = {}

export default class Panner {
  constructor (app, opts = {}) {
    this.opts = _defaultsDeep(opts, DEFAULT_OPTIONS)
    this.hasIndicator = false
    this.containerEl = this.opts.el
    this.pannerEl = Dom.find(this.containerEl, '[data-panner]')
    this.images = Dom.all(this.opts.el, '[data-panner-item] img')
    this.indicatorEl = Dom.find(this.containerEl, '[data-panner-indicator]')
    this.leftEl = Dom.find(this.containerEl, '[data-panner-left]')
    this.rightEl = Dom.find(this.containerEl, '[data-panner-right]')
    this.idx = 0
    this.disableRight = false
    this.disableLeft = true
    this.snap = false

    if (this.pannerEl.hasAttribute('data-panner-snap')) {
      this.snap = this.pannerEl.getAttribute('data-panner-snap')
    }

    // TODO
    this.snap = false

    if (this.indicatorEl) {
      this.hasIndicator = true
      this.setupIndicator()
    }

    if (this.rightEl) {
      this.hasDirections = true
      this.setupDirections()
    }

    this.setupPanner()
  }

  refreshBounds (waitForLoaded) {
    if (waitForLoaded) {
      imagesAreLoaded(this.images, false).then(() => {
        this.doRefresh()
      })
    } else {
      this.doRefresh()
    }
  }

  setPannerMinMax (minX) {
    this.pannerMaxX = 0
    if (minX) {
      this.pannerMinX = minX
    } else {
      this.pannerMinX = (this.containerEl.scrollWidth - this.containerEl.clientWidth) * -1
    }
  }

  doRefresh () {
    this.setPannerMinMax()

    this.applyBounds()

    if (this.hasIndicator) {
      gsap.set(this.indicatorNibEl, {
        width: this.indicatorEl.clientWidth / this.indicatorWidthFactor
      })
      this.indicator.applyBounds(this.indicatorEl)
    }
  }

  setupPanner () {
    gsap.set(this.containerEl, { overflowX: 'hidden', overflowY: 'hidden' })
    this.setPannerMinMax()
    const that = this

    let snap

    switch (this.snap) {
      case 'screen':
        snap = {
          x: gsap.utils.snap(window.innerWidth)
        }
        break

      default:
        break
    }

    const draggable = Draggable.create(this.pannerEl, {
      bounds: this.pannerEl,
      edgeResistance: 0.65,
      type: 'x',
      inertia: true,
      autoScroll: false,
      zIndexBoost: false,
      snap,
      onThrowUpdate () {
        if (that.hasIndicator) {
          const percent = Math.round(
            (Math.min(Math.max(this.x / this.minX, 0), 1) + Number.EPSILON) * 1000
          ) / 1000
          that.moveIndicatorToPercent(percent)
        }
      },
      onMove () {
        if (that.hasIndicator) {
          const percent = Math.round(
            (Math.min(Math.max(this.x / this.minX, 0), 1) + Number.EPSILON) * 1000
          ) / 1000
          that.moveIndicatorToPercent(percent)
        }
      }
    })

    // eslint-disable-next-line prefer-destructuring
    this.panner = draggable[0]
  }

  movePannerToPercent (percent) {
    const newX = this.pannerMinX * percent
    gsap.to(this.pannerEl, { x: newX, ease: 'power3' })
  }

  moveIndicatorToPercent (percent) {
    const newX = this.indicator.maxX * percent
    gsap.to(this.indicatorNibEl, { duration: 0.001, x: newX, ease: 'none' })
  }

  setupIndicator () {
    this.indicatorNibEl = Dom.find(this.containerEl, '[data-panner-indicator-nib]')
    this.indicatorWidthFactor = parseInt(this.indicatorNibEl.getAttribute('data-panner-indicator-nib'))

    gsap.set(this.indicatorNibEl, {
      width: this.indicatorEl.clientWidth / this.indicatorWidthFactor
    })
    const that = this

    const draggable = Draggable.create(this.indicatorNibEl, {
      type: 'x',
      bounds: this.indicatorEl,
      onDrag () {
        const percent = Math.round(
          (Math.min(Math.max(this.x / this.maxX, 0), 1) + Number.EPSILON) * 100
        ) / 100
        that.movePannerToPercent(percent)
      }
    })

    // eslint-disable-next-line prefer-destructuring
    this.indicator = draggable[0]
  }

  setupDirections () {
    this.rightEl.addEventListener('click', this.moveRight.bind(this))
    this.leftEl.addEventListener('click', this.moveLeft.bind(this))
    this.checkArrows()
  }

  checkArrows () {
    if (this.disableRight) {
      gsap.to(this.rightEl, { opacity: 0 })
    } else {
      gsap.to(this.rightEl, { opacity: 1 })
    }

    if (this.disableLeft) {
      gsap.to(this.leftEl, { opacity: 0 })
    } else {
      gsap.to(this.leftEl, { opacity: 1 })
    }
  }

  applyBounds () {
    this.panner.applyBounds({
      maxX: this.pannerMaxX, minX: this.pannerMinX
    })
  }


  moveLeft () {
    // find image
    if (this.idx === 0) {
      return
    }

    if (this.disableLeft) {
      return
    }

    const currentImg = this.images[this.idx]
    const currentImgRect = currentImg.getBoundingClientRect()
    this.idx -= 1
    const prevImg = this.images[this.idx]
    const prevImgRect = prevImg.getBoundingClientRect()
    const x = prevImgRect.x - currentImgRect.x

    this.panner.update()
    gsap.to(this.pannerEl, {
      x: `-=${x}`,
      ease: 'sine.inOut',
      onComplete: () => {
        this.panner.update()
        if (this.idx === 0) {
          this.disableLeft = true
        } else {
          this.disableLeft = false
        }
        this.disableRight = false
        this.checkArrows()
      }
    })
  }

  moveRight () {
    // find image
    if (this.idx >= this.images.length - 1) {
      return
    }

    if (this.disableRight) {
      return
    }

    // if the last image is inside, we're done
    const lastImage = this.images[this.images.length - 1]
    const lastImageRect = lastImage.getBoundingClientRect()

    const currentImg = this.images[this.idx]
    const currentImgRect = currentImg.getBoundingClientRect()
    this.idx += 1
    const nextImg = this.images[this.idx]
    const nextImgRect = nextImg.getBoundingClientRect()

    const x = nextImgRect.x - currentImgRect.x

    this.panner.update()

    gsap.to(this.pannerEl, {
      x: `-=${x}`,
      ease: 'sine.inOut',
      onComplete: () => {
        this.panner.update()

        const lastEdge = lastImageRect.x + lastImageRect.width
        const scrollPosRight = this.panner.x * -1 + window.innerWidth

        this.disableRight = false
        this.disableLeft = false

        if (this.panner.x < this.pannerMinX) {
          // next button triggered an x value that is less than
          // the boundary, so we set a new one.
          this.setPannerMinMax(this.panner.x)
          this.applyBounds()
        }

        if (lastEdge < scrollPosRight) {
          this.disableRight = true
          return
        }

        this.checkArrows()
      }
    })
  }
}
