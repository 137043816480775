/**
 * B&Y APPLICATION FRONTEND
 * (c) 2020 B&Y TM
 */

/**
 * JUPITER IMPORTS
 */
import {
  Application,
  Dom,
  Events,
  HeroSlider,
  Lazyload,
  Links,
  MobileMenu,
  Moonwalk,
  FixedHeader,
  gsap,
  imagesAreLoaded
} from '@univers-agency/jupiter'

/**
 * APP SPECIFIC MODULE IMPORTS
 */

import Panner from './modules/Panner'
// import SmartVideo from './modules/SmartVideo'
import Toggler from './modules/Toggler'


/**
 * CONFIG IMPORTS
 */
import configureBreakpoints from './config/BREAKPOINTS'
import configureHeader from './config/HEADER'
import configureMoonwalk from './config/MOONWALK'
import configureHero from './config/HERO_SLIDER'
import configureMenu from './config/MOBILE_MENU'

import '../css/app.pcss'

const app = new Application({
  breakpointConfig: configureBreakpoints,
  faderOpts: {
    fadeIn: (callback = () => { }) => {
      const fader = document.querySelector('#fader')
      callback()

      gsap.to(fader, {
        opacity: 0,
        ease: 'power1.inOut',
        delay: 0.3,
        duration: 0.4,
        onComplete: () => {
          gsap.set(fader, { display: 'none' })
          document.body.classList.remove('unloaded')
        }
      })
    }
  }
})

app.registerCallback(Events.APPLICATION_READY, () => {
  app.links = new Links(app, { emitEvents: false })
})


function getRenderedSize (contains, cWidth, cHeight, width, height, pos) {
  const oRatio = width / height;
  const cRatio = cWidth / cHeight;
  // eslint-disable-next-line func-names
  return function () {
    if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
      this.width = cWidth;
      this.height = cWidth / oRatio;
    } else {
      this.width = cHeight * oRatio;
      this.height = cHeight;
    }
    this.left = (cWidth - this.width) * (pos / 100);
    this.right = this.width + this.left;
    return this;
  }.call({});
}

function getImgSizeInfo (img) {
  const pos = window
    .getComputedStyle(img)
    .getPropertyValue('object-position')
    .split(' ')

  return getRenderedSize(true,
    img.width,
    img.height,
    img.naturalWidth,
    img.naturalHeight,
    parseInt(pos[0]))
}

function calcEqualHeights () {
  let lastBot = null
  const actionables = []
  let elements = []
  let height = 0

  const imgs = Dom.all('[data-object-fit-calc]')
  imagesAreLoaded(imgs, true).then(() => {
    imgs.forEach(el => {
      const rect = el.getBoundingClientRect()
      const size = getImgSizeInfo(el)

      if (lastBot === null) {
        height = size.height
        elements.push(el)
        lastBot = rect.bottom
        return
      }

      if (lastBot !== rect.bottom) {
        actionables.push({ elements, height })
        elements = []
        height = size.height
      } else if (lastBot === rect.bottom) {
        if (size.height > height) {
          height = size.height
        }
      } else {
        height = size.height
      }
      elements.push(el)
      lastBot = rect.bottom
    })

    if (elements.length) {
      actionables.push({ elements, height })
    }

    if (actionables.length) {
      actionables.forEach(a => {
        gsap.set(a.elements, { height: a.height })
      })
    }
  })
}

app.registerCallback(Events.APPLICATION_REVEALED, () => {
  app.panners.forEach(panner => {
    panner.refreshBounds(true)
  })
})

app.registerCallback(Events.APPLICATION_PRELUDIUM, () => {
  app.hero = new HeroSlider(app, configureHero(app))
  app.lazyload = new Lazyload(app, { useNativeLazyloadIfAvailable: false })
  app.header = new FixedHeader(app, configureHeader(app))
  app.menu = new MobileMenu(app, configureMenu(app))
  app.panners = []
  app.togglers = new Toggler(app)

  Dom.all('[data-panner-container]').forEach(c => {
    app.panners.push(new Panner(app, { el: c }))
  })

  window.addEventListener(Events.APPLICATION_RESIZE, () => {
    app.panners.forEach(panner => {
      panner.refreshBounds()
    })
  })

  const yearDropdownButton = Dom.find('button.year-selector-dropdown')

  if (yearDropdownButton) {
    const dropdown = Dom.find('.year-selector-content')
    const timeline = gsap.timeline({ paused: true })
    const items = Dom.all(dropdown, 'li')

    timeline
      .set(dropdown, { display: 'none ' })
      .set(items, { opacity: 0, x: -25 })
      .set(dropdown, { display: 'flex' })
      .to(items, {
        opacity: 1, x: 0, duration: 0.55, stagger: 0.1, ease: 'power2.out'
      })

    yearDropdownButton.addEventListener('click', () => {
      const showDropdown = yearDropdownButton.toggleAttribute('data-toggled')

      if (showDropdown) {
        timeline.play()
      } else {
        timeline.reverse()
      }
    })
  }

  calcEqualHeights()
  app.moonwalk = new Moonwalk(app, configureMoonwalk(app))
})

// trigger ready state
if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
  app.initialize()
} else {
  document.addEventListener('DOMContentLoaded', app.initialize.apply(app))
}


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept()
}
