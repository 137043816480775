import { gsap, Dom, Events } from '@univers-agency/jupiter'

const timeline = gsap.timeline({ paused: false })

export default () => ({
  lazyImages: false,
  onInitialize: () => {
    const els = Dom.all('[data-hero-width]')
    gsap.set(els, { width: document.body.clientWidth })
    window.addEventListener(Events.APPLICATION_RESIZE, () => {
      gsap.set(els, {
        width: document.body.clientWidth,
        overwrite: true
      })
    })
  },

  onTransition: hs => {
    const prevImg = Dom.find(hs._previousSlide, 'img')
    const prevText = Dom.all(hs._previousSlide, 'h2,.more')
    const prevH2 = Dom.all(hs._previousSlide, 'h2')
    const prevMore = Dom.all(hs._previousSlide, '.more')
    const currentH2 = Dom.all(hs._currentSlide, 'h2')
    const currentMore = Dom.all(hs._currentSlide, '.more')
    const currentImg = Dom.find(hs._currentSlide, 'img')
    const DURATION = 5

    timeline
      .set(hs._currentSlide, {
        zIndex: hs.opts.zIndex.next,
        width: '100%'
      })
      .set(currentH2, {
        opacity: 0,
        x: 20
      })
      .set(currentMore, {
        opacity: 0,
        x: 0
      })
      .set(currentImg, {
        scale: 1.0
      })
      .call(() => {
        gsap.to(prevH2, {
          opacity: 1,
          delay: 0,
          stagger: 0.3
        })
        gsap.to(prevH2, {
          stagger: 0.3,
          duration: 1,
          x: 0,
          ease: 'power3.out'
        })
        gsap.to(prevMore, { opacity: 1, delay: 0.35 })
      })
      .call(() => {
        gsap.to(prevText, {
          opacity: 0,
          delay: DURATION - 0.3
        })
        gsap.to(prevText, {
          delay: DURATION - 0.3,
          x: -20,
          ease: 'power3.in'
        })
        Dom.addClass(prevImg, 'zoom')
      })
      .fromTo(prevImg, {
        overflow: 'hidden'
      }, {
        duration: DURATION + 0.5,
        ease: 'none'
      })

      .to(hs._previousSlide, {
        duration: hs.opts.transition.duration,
        width: 0,
        ease: 'power3.in',
        autoRound: true,
        overwrite: 'preexisting'
      }, '-=0.8')
      .call(() => {
        Dom.removeClass(prevImg, 'zoom')
        Dom.addClass(currentImg, 'zoom')
      })
      .set(hs._nextSlide, {
        zIndex: hs.opts.zIndex.next
      })
      .set(hs._currentSlide, {
        zIndex: hs.opts.zIndex.visible,
        width: '100%'
      })
      .set(hs._previousSlide, {
        zIndex: hs.opts.zIndex.regular,
        width: '100%'
      })
      .set(prevImg, {
        scale: 1.0
      })
      .call(() => {
        hs.next()
      })
  }
})
