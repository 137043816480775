import { Dom, gsap } from '@univers-agency/jupiter'

export default () => ({
  el: 'header[data-nav]',
  default: {
    unPinOnResize: false,
    altBgColor: '#1b1b1b',

    beforeEnter: h => {
      const brand = Dom.find(h.el, '.brand')
      const links = Dom.all(h.el, '.main ul li')

      h.items = [brand, links]

      const timeline = gsap.timeline()
      timeline
        .set(h.items, { opacity: 0, x: -15 })
    },

    enter: h => {
      const timeline = gsap.timeline()
      timeline
        .to(h.items, {
          duration: 1,
          opacity: 1,
          x: 0,
          delay: 0,
          ease: 'power3.out',
          autoRound: true,
          stagger: 0.2
        })
    },

    offsetBg: 140,
    offset: 80,
    offsetSmall: 50
  }
})
