import { gsap, Dom } from '@univers-agency/jupiter'

export default () => ({
  logoColor: '#ffffff',
  hamburgerColor: '#ffffff',
  contentSelector: 'section.main',

  onResize: m => {
    if (document.body.classList.contains('open-menu')) {
      gsap.to(m.bg, { duration: 0.1, height: window.innerHeight })
    }
  },

  openTween: m => {
    const timeline = gsap.timeline()

    m.hamburger.classList.toggle('is-active')
    document.body.classList.toggle('open-menu')
    const navDistanceFromTop = m.nav.getBoundingClientRect().y
    const windowHeightWithoutMargins = window.innerHeight - (navDistanceFromTop * 2)

    const menuPaths = Dom.all(m.hamburger, 'path')
    const offsetY = 8
    gsap.to(menuPaths[0], {
      duration: 0.15,
      y: offsetY,
      ease: 'sine.in'
    })

    gsap.to(menuPaths[2], {
      duration: 0.15,
      y: offsetY * -1,
      ease: 'sine.in',
      onComplete: () => {
        gsap.to(menuPaths[1], {
          duration: 0.2, opacity: 0, scale: 0, ease: 'circ.out'
        })
        gsap.to(menuPaths[0], {
          duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: 'circ.out'
        })
        gsap.to(menuPaths[2], {
          duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: 'circ.out'
        })
      }
    })

    timeline
      .timeScale(1.8)
      .set(m.lis, { autoAlpha: 0 })
      .set(m.bg, { display: 'block' })
      .fromTo(m.bg, 0.35, { x: '0%', opacity: 0, height: window.innerHeight }, { opacity: 1, ease: 'sine.in' })
      .set(m.nav, { height: windowHeightWithoutMargins })
      .call(() => {
        m.logo.classList.toggle('white')
        m.hamburger.classList.toggle('white')
        gsap.set(m.content, { display: 'block' })
        m.hamburger.classList.toggle('close')
      })
      .staggerFromTo(m.lis, 1, { x: 20 }, { x: 0, autoAlpha: 1, ease: 'power3.out' }, 0.05)
      .call(m._emitMobileMenuOpenEvent)
  },

  closeTween: m => {
    document.body.classList.toggle('open-menu')
    const timeline = gsap.timeline()

    const menuPaths = Dom.all(m.hamburger, 'path')
    gsap.to(menuPaths[0], {
      duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: 'sine.in'
    })
    gsap.to(menuPaths[2], {
      duration: 0.1,
      rotationZ: '0deg',
      transformOrigin: '50% 50%',
      ease: 'sine.in',
      onComplete: () => {
        gsap.to(menuPaths[1], {
          duration: 0.15, scale: 1, opacity: 1, ease: 'quart.out'
        })
        gsap.to(menuPaths[0], {
          duration: 0.15, y: 0, ease: 'quart.out', clearProps: 'all'
        })
        gsap.to(menuPaths[2], {
          duration: 0.15, y: 0, ease: 'quart.out', clearProps: 'all'
        })
      }
    })


    timeline
      .timeScale(1.8)
      .call(() => { m.hamburger.classList.toggle('is-active') })
      .staggerTo(m.lis, 0.5, { opacity: 0, x: 20, ease: 'power3.out' }, 0.04, '-=0.4')
      .set(m.nav, { clearProps: 'height' })
      .set(m.content, { display: 'none' })
      .call(() => {
        m.logo.classList.toggle('white')
        m.hamburger.classList.toggle('white')
        m.hamburger.classList.toggle('close')
      })
      .to(m.bg, 1.25, { opacity: 0, ease: 'sine.in' }, '-=0.3')
      .call(() => { m._emitMobileMenuClosedEvent() })
      .set(m.lis, { clearProps: 'opacity' })
      .set(m.bg, { display: 'none' })
  }
})
