export default () => ({
  rootMargin: '-10% 0%',
  threshold: 0,
  initialDelay: 0,

  walks: {
    default: {
      interval: 0.2, // was 0.03
      duration: 0.65,
      transition: null
    },

    reveal: {
      interval: 0.1,
      duration: 0.75,
      startDelay: 0.5,
      alphaTween: true,
      transition: {
        from: {
          x: -100
        },

        to: {
          x: 0,
          ease: 'power3.out'
        }
      }
    },

    slide: {
      interval: 0.2, // was 0.03
      duration: 0.65,
      alphaTween: false,
      transition: null
    },

    slideLeft: {
      interval: 0.2, // was 0.03
      duration: 0.65,
      alphaTween: true,
      transition: null
    },

    panner: {
      interval: 0.2, // was 0.03
      duration: 0.65,
      alphaTween: true,
      transition: {
        from: {
          y: 40,
          autoAlpha: 0
        },

        to: {
          y: 0,
          autoAlpha: 1,
          ease: 'power2.out'
        }
      },
      sectionTargets: '[data-panner-item]'
    },

    scale: {
      duration: 0.65,
      interval: 0.1,
      transition: {
        from: {
          xPercent: -100,
          autoAlpha: 0
        },

        to: {
          xPercent: 0,
          autoAlpha: 1,
          ease: 'power2.in'
        }
      }
    },

    fast: {
      duration: 0.2,
      interval: 0.07,
      transition: {
        from: {
          x: 8,
          autoAlpha: 0
        },

        to: {
          x: 0,
          autoAlpha: 1
        }
      }
    }
  }
})
