import { Dom } from '@univers-agency/jupiter';

export default class Toggler {
  constructor () {
    const toggleButtons = Dom.all('[data-toggle-target]')
    Array.from(toggleButtons).forEach(b => {
      const targetElement = b.getAttribute('data-toggle-target')
      const $targetElement = Dom.find(`[data-toggle="${targetElement}"]`)

      b.addEventListener('click', () => { this.toggle(b, $targetElement) })
    })
  }

  toggle (button, $targetElement) {
    button.toggleAttribute('data-toggled')
    $targetElement.toggleAttribute('data-toggled')
  }
}
